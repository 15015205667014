import React from 'react';
import Typography from '@material-ui/core/Typography';

let i = 0;
let count = 0;
const messages = [
  'I develop things with NodeJS            ',
  'I design websites with React            ',
  'I am AWS certified            '
];

class Description extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      end: false
    };
  }

  componentDidMount() {
    this.typingID = setInterval(() => this.type(), 50);
  }

  componentWillUnmount() {
    clearInterval(this.typingID);
  }

  type() {
    const { end, message } = this.state;
    if (!end && count < messages[i].length) {
      this.setState({
        message: message + messages[i][count]
      });
      count += 1;
    } else if (count > 2) {
      count -= 1;
      this.setState({
        end: true,
        message: message.slice(0, count)
      });
    } else {
      if (i + 1 === messages.length) {
        i = 0;
      } else {
        i += 1;
      }
      this.setState({
        end: false
      });
    }
  }

  render() {
    const { message } = this.state;
    return (
      <div className="Ticker-container">
        <Typography className="description" variant="subtitle1">
          {message}
        </Typography>
      </div>
    );
  }
}

export default Description;
