import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import GitHub from 'components/icons/GitHub';
import LinkedIn from 'components/icons/LinkedIn';
import Email from 'components/icons/Email';
import AWSCertifiedBadge from 'images/AWSCertified.png';

const Footer = () => {
  return (
    <Typography>
      <Link
        color="secondary"
        className="Socials"
        href="mailto:matthew@matthewhatton.co.nz"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Email className="icon" fontSize="large" />
      </Link>
      <Link
        color="secondary"
        className="Socials"
        href="https://www.github.com/matthackin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GitHub className="icon" fontSize="large" />
      </Link>
      <Link
        color="secondary"
        className="Socials"
        href="https://nz.linkedin.com/in/matthackin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedIn fontSize="large" />
      </Link>
      <Link
        color="secondary"
        className="Socials"
        href="https://www.credly.com/badges/ef23181d-2abf-4fe1-a796-2e1463154173/linked_in?t=rm6b7v"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={AWSCertifiedBadge}
          alt="AWS Certified Developer - Associate"
          className="AWSLogo"
        />
      </Link>
    </Typography>
  );
};

export default Footer;
