import React from 'react';
import Typography from '@material-ui/core/Typography';

const Header = () => {
  return (
    <div className="Header-container">
      <Typography className="Name-heading" variant="h1">
        Matthew Hatton
      </Typography>
      <Typography className="postNominals" align="right" variant="subtitle2">
        BA GradDipSci UOA
      </Typography>
    </div>
  );
};

export default Header;
