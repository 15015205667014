import React from 'react';
import Description from 'components/sections/description';
// import Projects from 'components/sections/projects';
import Footer from 'components/sections/footer';
import Header from 'components/sections/header';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7D7F6F'
    },
    secondary: {
      main: '#82ddf0'
    }
  },
  typography: {
    subtitle1: {
      margin: '32px 0 64px 0',
      minHeight: '32px'
    },
    h1: {
      marginLeft: '24px',
      marginRight: '24px'
    },
    h2: {
      margin: '32px 0 64px 0',
      minHeight: '32px'
    },
    subtitle2: {
      marginLeft: '24px',
      marginRight: '24px'
    }
  }
});

function App() {
  return (
    <div className="App-header">
      <ThemeProvider theme={theme}>
        <Header />
        <Description />
        {/* <Projects /> */}
        <Footer />
      </ThemeProvider>{' '}
    </div>
  );
}

export default App;
